<template>
  <div>
    <SButtonCreateOrder class="mb-3" to="auction/new" />
    <OrderList
      store="order.order_auction"
      resource="/sale/orders/auction"
      orderStatusStore="order.auction_statuses"
    />
  </div>
</template>

<script>
import OrderList from "../components/OrderList.vue";

export default {
  components: { OrderList },

};
</script>
